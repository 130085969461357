//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    component: {
      type: String,
      default: "input"
    },
    field: {
      type: Object,
      default: null
    },
    value: {
      type: String
    }
  },
  computed: {
    uid() {
      return this._uid;
    },
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  }
};